import React from 'react';

const TrafficLight = ({trafficLevel}) => {

    const decideClass = (own, level, css) => {
        if(own === level) return css;
        else return "light off";
    }

    return (
        <div>
            <div className={"traffic-light traffic-light-large"}>
                <div className={decideClass(2,trafficLevel,"light red")}></div>
                <div className={decideClass(1,trafficLevel,"light yellow")}></div>
                <div className={decideClass(0,trafficLevel,"light green")}></div>
            </div>
        </div>
    );
}

export { TrafficLight };