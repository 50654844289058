import React from 'react';

const MainTrafficLevelHeader = ({trafficLevel, title}) => {

    let level = trafficLevel != null ? trafficLevel : 0;

    const renderSubtitle = (level) => {
        if(parseInt(level) === 2) {
            return <h2 className={"red"}>Hohe Auslastung</h2>
        } else if(parseInt(level) === 1) {
            return <h2 className={"yellow"}>Mittlere Auslastung</h2>;
        } else {
            return <h2 className={"green"}>Niedrige Auslastung</h2>;
        }
    }

    return (
        <div>
            <h1>{title ? title : "-"}</h1>
            {renderSubtitle(level)}
        </div>
    );
}

export { MainTrafficLevelHeader };