import dotenv from "dotenv";
dotenv.config();

/***********************************************
 * Global Config Class
 *
 * Reads config Values from Env (process.env) or
 * replaces the Values at build time for prod
 ***********************************************/

// polyfill for IE11
/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

export default class Config {
  /**
   * config
   *
   * Getter for config object
   */
  static get config() {
    return {
      backendHost: "$REACT_APP_BACKEND_HOST", // this will be replaced at buildtime
    };
  }

  /**
   * get ()
   *
   * returns the value of the config object
   */
  static get(key) {
    // error not found
    if (!(key in this.config)) {
      console.log(`Config Error: There is no key named "${key}"`);
      return;
    }

    // get the value from this.config
    const value = this.config[key];

    // error not found
    if (!value) {
      console.log(`Config Error: Value for "${key}" is not defined`);
      return;
    }

    if (value.startsWith("$REACT_APP_")) {
      /******************************************
       * Development Mode
       * ENV-Vars are not replaced
       ******************************************/

      // value was not replaced, it seems we are in development.
      // Remove $ and get current value from process.env
      const envName = value.substr(1);
      const envValue = process.env[envName];

      /*  console.log("DEVELOPMENT MODE: ",process.env);
       */
      if (envValue) {
        return envValue;
      } else {
        console.log(
          `Config Error (DEV Mode): Environment variable "${envName}" is not defined`
        );
      }
    } else {
      /******************************************
       * Prod Mode
       * Get replaced Value
       ******************************************/

      // value was already replaced, it seems we are in production.
      return value;
    }
  }
}
