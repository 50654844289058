import React from 'react';

const QRCode = () => {

    return (
        <div>
            <p>Mehr Infos auf Strandticker.de</p>
            <div><img className="logo" src={require('../../assets/img/qrcode.svg')} alt=""/></div>
        </div>
    );
}

export { QRCode };