import {
    FETCH_THINGS,
    FETCH_THINGS_SUCCESS,
    FETCH_THINGS_ERROR
} from "./types";
import { httpClient } from "../helpers";
import {
    URL_BACKEND_SERVER,
    URL_BACKEND_THING
} from "../configuration";



export const fetchThings = (code) => {
    return async dispatch => {
        dispatch({type: FETCH_THINGS})

        try {
            let response = await httpClient.get(`${URL_BACKEND_SERVER}${URL_BACKEND_THING}`,{ params: { code } });
            dispatch({type: FETCH_THINGS_SUCCESS, payload: response.data})
        } catch (err) {
            console.log("[Thing] => fetching failed", err);
            dispatch({type: FETCH_THINGS_ERROR, errorMessage: "Error fetching things: " + err});
        }
    }
}

