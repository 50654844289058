import Config from './Config';

/******************************************
 * General
 ******************************************/

export const URL_BACKEND_SERVER = Config.get('backendHost');
export const URL_BACKEND_THING = 'kiosk_endpoint';


