import axios from 'axios';


axios.interceptors.request.use(async (config) => {
        const token = window.localStorage.getItem('authToken');
        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        //config.headers['X-Frame-Options'] = 'DENY';
        //config.headers['X-Content-Type-Options'] = 'nosniff';
        //config.headers['X-XSS-Protection'] = '1; mode=block';
        return config;
    }, (error) => {
        return Promise.reject(error);
    }
)

export { axios as httpClient };
