import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Kiosk from './Kiosk';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/App.css';
import '../assets/css/LHIND-Theme.css';

function App() {
  return (
      <Router forceRefresh={false}>
        <div className="root-inner">

            <Route exact path="/:thingId/:code" component={Kiosk} />

        </div>
      </Router>
  );
}


export default App;
