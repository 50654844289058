import React from 'react';

const TrafficLightMini = ({title, trafficLevel}) => {

    let level = trafficLevel != null ? trafficLevel : 0;

    const renderSubtitle = (level) => {
        if(parseInt(level) === 2) {
            return <h5 className={"red"}>Hohe Auslastung</h5>
        } else if(parseInt(level) === 1) {
            return <h5 className={"yellow"}>Mittlere Auslastung</h5>;
        } else {
            return <h5 className={"green"}>Niedrige Auslastung</h5>;
        }
    }

    const decideClass = (own, level, css) => {
        if(own === level) return css;
        else return "light off";
    }

    return (
        <div>
            <div>
                <h4>{title}</h4>
                {renderSubtitle(level)}
            </div>
            <div className={"traffic-light traffic-light-mini"}>
                <div className={decideClass(2,trafficLevel,"light red")}></div>
                <div className={decideClass(1,trafficLevel,"light yellow")}></div>
                <div className={decideClass(0,trafficLevel,"light green")}></div>
            </div>
        </div>
    );
}

export { TrafficLightMini };