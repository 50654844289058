import {
    FETCH_THINGS,
    FETCH_THINGS_ERROR,
    FETCH_THINGS_SUCCESS
} from "../actions/types";

const INITIAL_STATE = {
    list: []
}

const things = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_THINGS:
            return state;

        case FETCH_THINGS_SUCCESS:
            // remove things without thingOrder, with draw=false and with trafficLevel and forcedTrafficLevel both null
            let list = action.payload.filter(t => t.thingOrder != null && t.thingOrder >= 0 && t.draw === true && ( (t.trafficLevel != null && t.trafficLevel >= 0) || (t.forcedTrafficLevel != null && t.forcedTrafficLevel >= 0) ) );

            // order list by thingOrder
            list.sort((a,b) => a.thingOrder > b.thingOrder ? 1 : a.thingOrder < b.thingOrder ? -1 : 0);

            return {...state, list: list};

        case FETCH_THINGS_ERROR:
            return state;

        default:
            return state;
    }
}

export {things}

