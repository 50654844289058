// format time string
export const getDateTimeString = () => {
    var today = new Date();
    var h = today.getHours() < 10 ? "0"+today.getHours() : today.getHours();
    var m = today.getMinutes() < 10 ? "0"+today.getMinutes() : today.getMinutes();
    var s = today.getSeconds() < 10 ? "0"+today.getSeconds() : today.getSeconds();

    var y = today.getFullYear();
    var M = today.getMonth() < 10 ? "0" + today.getMonth() : today.getMonth();
    var d = today.getDate() < 10 ? "0"+today.getDate() : today.getDate();

    return d+"."+M+"."+y+" "+h+":"+m+":"+s;

}
