import React, {useEffect,useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';

import { fetchThings } from "../actions/index";
import { getDateTimeString } from '../helpers';
import { MainTrafficLevelHeader, TrafficLightMini, QRCode, TrafficLight } from "./common";


/**
 * Main Kiosk Component
 * Displays the Traffic Lights and current alternatives
 * @param props
 * @returns {*}
 * @constructor
 */
const Kiosk = (props) => {

    const RELOAD_TIMEOUT = 30000;

    // hooks
    const dispatch = useDispatch();
    const params = useParams();
    const passages = useSelector(state => state.things.list);
    const [timer, setTimer] = useState(null);

    // helpers
    const isObject = v => (v !== null && typeof v === 'object');

    // decode function access code
    const code = decodeURIComponent(params.code);

    // fetch things at launch
    useEffect(() => {
        dispatch(fetchThings(code));
        console.log("fetch");

        // reload
        let interval = setInterval(() => dispatch(fetchThings(code)), RELOAD_TIMEOUT);
        setTimer(interval);
    },[dispatch, code]);



    // get the current main thing for display
    let currentPassage = passages.filter(p => p.id === parseInt(params.thingId))[0];
    currentPassage = isObject(currentPassage) ? currentPassage : {};

    // get the passages next to the current
    var leftPassage = passages.filter(p => parseInt(p.thingOrder) < parseInt(currentPassage.thingOrder));
    var rightPassage = passages.filter(p => parseInt(p.thingOrder) > parseInt(currentPassage.thingOrder));

    if(leftPassage.length <= 0 && rightPassage) {
        // left end
        leftPassage = rightPassage[0];
        rightPassage = rightPassage[1];
    } else if(leftPassage && rightPassage.length <= 0) {
        // right end
        rightPassage = leftPassage[leftPassage.length - 2];
        leftPassage = leftPassage[leftPassage.length - 1];
    } else {
        // normal
        leftPassage = leftPassage[leftPassage.length - 1];
        rightPassage = rightPassage[0];
    }

    rightPassage = isObject(rightPassage) ? rightPassage : {};
    leftPassage = isObject(leftPassage) ? leftPassage : {};


    /**
     * Render Kiosk Component
     */
    return (
        <div>
            <div className={"kiosk"}>
                <div className={"kiosk-left"}>
                    <div>
                        <div className={"main-status"}>
                            <MainTrafficLevelHeader trafficLevel={currentPassage.forcedTrafficLevel} title={currentPassage.name} />
                        </div>
                        <div className={"next-areas"}>
                            <div className={"next-areas-left"}>
                                <TrafficLightMini trafficLevel={leftPassage.forcedTrafficLevel} title={leftPassage.name}  />
                                <TrafficLightMini trafficLevel={rightPassage.forcedTrafficLevel} title={rightPassage.name}  />
                            </div>
                            <div className={"next-areas-right"}>
                                <QRCode />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"kiosk-right"}>
                    <TrafficLight trafficLevel={currentPassage.forcedTrafficLevel} />
                </div>
            </div>
            <div className={"timestamp"}>
                <h4>Letzte Aktualisierung {getDateTimeString()}</h4>
            </div>
        </div>
    );
};

export default Kiosk;
